import { createSlice } from '@reduxjs/toolkit';

interface MainState {
    currentRoomIndex: number;
    section: number;
    zoom: number;
    shapeModal: any;
    infoModal: any;
    menuModal: any;
    roomFloorModal: any;
    roomFloorMapsModal: any;
    AGModal: any;
    ARModal: any;
    sound: boolean;
    animation: any;
    currentDistance: number;
}

const initialState: MainState = {
    currentRoomIndex: 0,
    section: 0,
    zoom: 1,
    shapeModal: {
        open: false
    },
    infoModal: {
        open: false
    },
    menuModal: {
        open: false
    },
    roomFloorModal: {
        open: false
    },
    roomFloorMapsModal: {
        open: false
    },
    AGModal: {
        open: false
    },
    ARModal: {
        open: false
    },
    sound: false,
    animation: null,
    currentDistance: 150
};

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        switchRoom: (state, actions) => {
            state.currentRoomIndex = JSON.parse(actions.payload);
        },
        switchSection: (state, actions) => {
            state.section = JSON.parse(actions.payload);
        },
        setZoom: (state, actions) => {
            state.zoom = JSON.parse(actions.payload);
        },
        setShapeModalOpen: (state, actions) => {
            state.shapeModal.open = JSON.parse(actions.payload);
        },
        setInfoModalOpen: (state, actions) => {
            state.infoModal.open = JSON.parse(actions.payload);
        },
        setMenuModalOpen: (state, actions) => {
            state.menuModal.open = JSON.parse(actions.payload);
        },
        setRoomFloorModalOpen: (state, actions) => {
            state.roomFloorModal.open = JSON.parse(actions.payload);
        },
        setRoomFloorMapsModalOpen: (state, actions) => {
            state.roomFloorMapsModal.open = JSON.parse(actions.payload);
        },
        setAGModalOpen: (state, actions) => {
            state.AGModal.open = JSON.parse(actions.payload);
        },
        setARModalOpen: (state, actions) => {
            state.ARModal.open = JSON.parse(actions.payload);
        },
        setSound: (state, actions) => {
            state.sound = JSON.parse(actions.payload);
        },
        setAnimation: (state, actions) => {
            state.animation = JSON.parse(actions.payload);
        },
        setCurrentDistance: (state, actions) => {
            state.currentDistance = +JSON.parse(actions.payload);
        },
    },
});

export const { switchRoom, switchSection, setZoom, setShapeModalOpen, setInfoModalOpen, setMenuModalOpen, setRoomFloorModalOpen, setRoomFloorMapsModalOpen, setAGModalOpen, setSound, setAnimation, setARModalOpen, setCurrentDistance } = mainSlice.actions;

export default mainSlice.reducer;

export const mainSelector = (state: any) => state.main;

export function SwitchSection(section: number) {
    return async (dispatch: any) => {
        try {
            dispatch(switchSection(JSON.stringify(section)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function SwitchRoom(room: number) {
    return async (dispatch: any) => {
        try {
            dispatch(switchRoom(JSON.stringify(room)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function SetZoom(zoom: number) {
    return async (dispatch: any) => {
        try {
            dispatch(setZoom(JSON.stringify(zoom)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function ShapeModalOpen(open: boolean) {
    return async (dispatch: any) => {
        try {
            dispatch(setShapeModalOpen(JSON.stringify(open)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function InfoModalOpen(open: boolean) {
    return async (dispatch: any) => {
        try {
            dispatch(setInfoModalOpen(JSON.stringify(open)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function MenuModalOpen(open: boolean) {
    return async (dispatch: any) => {
        try {
            dispatch(setMenuModalOpen(JSON.stringify(open)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function RoomFloorModalOpen(open: boolean) {
    return async (dispatch: any) => {
        try {
            dispatch(setRoomFloorModalOpen(JSON.stringify(open)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function RoomFloorMapsModalOpen(open: boolean) {
    return async (dispatch: any) => {
        try {
            dispatch(setRoomFloorMapsModalOpen(JSON.stringify(open)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function AGModalOpen(open: boolean) {
    return async (dispatch: any) => {
        try {
            dispatch(setAGModalOpen(JSON.stringify(open)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function ARModalOpen(open: boolean) {
    return async (dispatch: any) => {
        try {
            dispatch(setARModalOpen(JSON.stringify(open)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function SetSound(active: boolean) {
    return async (dispatch: any) => {
        try {
            dispatch(setSound(JSON.stringify(active)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function SetAnimation(animation: any) {
    return async (dispatch: any) => {
        try {
            dispatch(setAnimation(JSON.stringify(animation)));
        } catch (error) {
            console.log(error);
        }
    };
}

export function SetCurrentDistance(distance: any) {
    return async (dispatch: any) => {
        try {
            dispatch(setCurrentDistance(JSON.stringify(distance)));
        } catch (error) {
            console.log(error);
        }
    };
}