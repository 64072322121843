import React from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {useDispatch, useSelector} from "react-redux";
import {mainSelector, ARModalOpen} from "../../store/slices/main.slice";

const ARModalRoot = () => {
    const dispatch: any = useDispatch();
    const { ARModal } = useSelector(mainSelector);

    const onClose = () => {
        dispatch(ARModalOpen(false))
    }

    return (
        <AnimatePresence>
            {ARModal.open && (
                <motion.div
                    key="arModal-root"
                    className="fixed top-0 left-0 w-full h-full bg-black/40 flex justify-center items-center z-[100]"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { duration: 0.5 } }}
                    onClick={onClose}
                >
                    <motion.div
                        key="shapeModal-content"
                        className="relative p-[80px] py-[120px] w-[60vw] bg-[#302e2b]"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.5, delay: 0 } }}
                        exit={{ opacity: 0, transition: { duration: 0.5 }}}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button onClick={onClose} className="text-white text-md font-untitledSans leading-[15px] absolute top-[30px] right-[30px]">X</button>
                        <div className="flex flex-col gap-[30px] items-center w-full mt-[-50px]">
                            <h4 className="text-white text-base font-untitledSans mb-[30px] w-full text-center">Inquadra il QR-Code per visualizzare il Palazzo in realtà aumentata</h4>
                            <div className="w-full max-h-[300px] flex flex-col items-center justify-center overflow-hidden">
                                <img src="/images/qr-code-ar.jpg" alt="QR Code AR" className={`max-h-[300px] h-full w-[auto]`}/>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ARModalRoot;
